import nextArrow from '../../../../assets/fiat/icon/nextArrow.svg';
import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import checkMark from '../../../../assets/fiat/icon/checkMark.svg';
import checkboxTick from '../../../../assets/fiat/icon/checkboxTick.svg';
import closeIcon from '../../../../assets/fiat/icon/closeIcon.svg';
import filterOpener from '../../../../assets/fiat/icon/filterOpener.svg';
import prevArrow from '../../../../assets/fiat/icon/prevArrow.svg';
import accordionChevron from '../../../../assets/fiat/icon/accordion.svg';
import docExtension from '../../../../assets/fiat/icon/extension.svg';
import facebook from '../../../../assets/fiat/icon/facebook.svg';
import facebook_small from '../../../../assets/fiat/icon/facebook_small.svg';
import instagram_small from '../../../../assets/fiat/icon/instagram_small.svg';
import instagram from '../../../../assets/fiat/icon/instagram.svg';
import twitter from '../../../../assets/fiat/icon/twitter.svg';
import twitter_small from '../../../../assets/fiat/icon/twitter_small.svg';
import linkedin from '../../../../assets/fiat/icon/linkedin.svg';
import linkedin_small from '../../../../assets/fiat/icon/linkedin_small.svg';
import youtube from '../../../../assets/fiat/icon/youtube.svg';
import youtube_small from '../../../../assets/fiat/icon/youtube_small.svg';
import newVehicles from '../../../../assets/fiat/icon/passengercarsales.svg';
import fiatPassengerCarsSalesIcon from '../../../../assets/fiat/icon/passengercarsales.svg';
import open from '../../../../assets/fiat/icon/open.svg';
import phone from '../../../../assets/fiat/icon/phone.svg';
import poi from '../../../../assets/fiat/icon/poi.svg';
import usedVehicles from '../../../../assets/fiat/icon/usedcars.svg';
import fiatDefaultServiceIcon from '../../../../assets/fiat/icon/usedcars.svg';
import fiatServiceValetIcon from '../../../../assets/fiat/icon/valet.svg';
import fiatPassengerCarsServiceIcon from '../../../../assets/fiat/icon/service.svg';
import teamMemberPlaceholder from '../../../../assets/fiat/icon/teamMemberPlaceholder.svg';
import downArrow from '../../../../assets/fiat/downArrow.svg';
import nextArrowLight from '../../../../assets/fiat/icon/nextArrowLight.svg';
import nextArrowSmall from '../../../../assets/fiat/icon/nextArrowSmall.svg';
import prevArrowLight from '../../../../assets/fiat/icon/prevArrowLight.svg';
import prevArrowSmall from '../../../../assets/fiat/icon/prevArrowSmall.svg';
import deleteIcon from '../../../../assets/fiat/icon/delete.svg';
import formDateInput from '../../../../assets/fiat/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/fiat/icon/form-time-input.svg';
import phoneIcon from '../../../../assets/fiat/icon/phoneIcon.svg';
import dealerLocatorArrow from '../../../../assets/fiat/icon/dealerLocatorArrow.svg';
import prevArrowLightWithBackground from '../../../../assets/fiat/icon/prevArrowLightWithBackground.svg';
import nextArrowLightWithBackground from '../../../../assets/fiat/icon/nextArrowLightWithBackground.svg';
import tiktok from '../../../../assets/fiat/icon/tiktok.svg';
import tiktok_small from '../../../../assets/fiat/icon/tiktok_small.svg';
import infoIcon from '../../../../assets/fiat/icon/infoIcon.svg';
import infoIconGray from '../../../../assets/fiat/icon/info_icon_gray.svg';

export const fiatAssets = Object.freeze({
  infoIcon,
  infoIconGray,
  deleteIcon,
  dealerLocatorArrow,
  phoneIcon,
  prevArrowLight,
  prevArrowSmall,
  nextArrowLight,
  nextArrowSmall,
  nextArrow,
  downArrow,
  prevArrow,
  noImagePlaceholder,
  checkMark,
  checkboxTick,
  closeIcon,
  filterOpener,
  accordionChevron,
  docExtension,
  facebook,
  facebook_small,
  instagram,
  instagram_small,
  twitter,
  twitter_small,
  linkedin,
  linkedin_small,
  youtube,
  youtube_small,
  open,
  phone,
  poi,
  usedVehicles,
  newVehicles,
  fiatDefaultServiceIcon,
  fiatServiceValetIcon,
  fiatPassengerCarsSalesIcon,
  fiatPassengerCarsServiceIcon,
  teamMemberPlaceholder,
  formDateInput,
  formTimeInput,
  prevArrowLightWithBackground,
  nextArrowLightWithBackground,
  tiktok,
  tiktok_small,
});
